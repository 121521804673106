import { createI18n } from 'vue-i18n'
import en from '@/locales/en.json'

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: { en },
  silentFallbackWarn: true,
  silentTranslationWarn: true
})

export default i18n