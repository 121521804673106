<template>
  <div
    v-show="discountModalVisible && hasActiveDiscount"
    class="modal discount-modal"
  >
    <div class="overlay" @click="handleCloseModal" />
    <div class="wrapper">
      <div class="container">
        <div class="modal-header">
          <h2 class="modal-title">{{ $t("Thank you for your purchase!") }}</h2>
          <img
            src="./../assets/img/close.svg"
            @click="handleCloseModal"
            class="close-button"
          />
        </div>
        <p class="discount-message" v-html="discountMessage"></p>

        <!-- <div v-if="products.length" class="product-cards">
                    <div class="product-card" v-for="product in products" :key="product.id"
                        @click="goToProduct(product)">
                        <img :src="getImageUrl(product)" class="product-image" :alt="getTitle(product)" />
                        <div class="product-info">
                            <div class="product-title">{{ getTitle(product) }}</div>
                            <div class="price-container" :class="{ 'has-discount': product.old_price }">
                                <template v-if="product.old_price">
                                    <div class="old-price">{{ product.old_price }} {{ currencySymbol }}</div>
                                    <div class="price discounted">
                                        <span>
                                            <b>{{ product.price }} {{ currencySymbol }}</b>
                                        </span>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="price blue">
                                        <span>
                                            <b>{{ product.price }}</b>
                                            <span class="currency">
                                                <b>{{ currencySymbol }}</b>
                                            </span>
                                        </span>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div> -->
        <button class="market-button button discount-modal" @click="goToMarket">
          {{ $t("Browse more") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "DiscountModal",
  props: {
    isAuth: {
      type: Boolean,
      required: true,
    },
    currencySymbol: {
      type: String,
      required: true,
    },
    currencyCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      imgDomain: "",
    };
  },
  computed: {
    ...mapGetters("discount", [
      "hasActiveDiscount",
      "getDiscountPercentage",
      "getDiscountDuration",
      "getProducts",
      "discountModalVisible",
    ]),
    discountDuration() {
      return this.getDiscountDuration;
    },
    discountPercentage() {
      return this.getDiscountPercentage;
    },
    discountMessage() {
      return this.$t("discount_message", {
        discountDuration: `<span class="highlight">${this.discountDuration}min</span>`,
        discountPercentage: `<span class="highlight">${this.discountPercentage}%</span>`,
        discountText: this.$t("discount_text"),
      });
    },
    products() {
      return this.getProducts;
    },
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    ...mapActions("discount", ["closeDiscountModal"]),
    goToProduct(item) {
      this.$emit("goToProduct", item);
      this.closeDiscountModal();
    },
    handleCloseModal() {
      this.closeDiscountModal();
    },
    goToMarket() {
      this.closeDiscountModal();
      this.$router.push("/products/all");
    },
    getTitle(item) {
      return item.title || item.name;
    },
    getImageUrl(item) {
      if (!item || !item.img_url || !item.item_type) {
        return "";
      }
      return item.item_type === "pack"
        ? item.img_url
        : `${this.imgDomain}${item.img_url}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal.discount-modal {
  color: #fff;

  .wrapper {
    // max-width: 1115px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    box-shadow: 0px 8px 8px 0px #0000001f;
    background: #3a3f56;
    overflow: hidden;

    // &::before,
    // &::after {
    //     content: '';
    //     position: absolute;
    //     width: 354px;
    //     height: 354px;
    //     border-radius: 50%;
    //     background-color: #124f4c;
    //     opacity: 0.4;
    //     filter: blur(60px);
    // }

    // &::before {
    //     bottom: -124px;
    //     left: -110px;
    // }

    // &::after {
    //     top: -79px;
    //     right: -145px;
    // }
  }

  .container {
    padding: 32px;
    background: transparent;
    display: flex;
    flex-direction: column;
  }

  .modal-header {
    display: flex;
    // justify-content: center;
    align-items: center;
    margin-bottom: 24px;
  }

  .modal-title {
    font-size: 32px;
    font-weight: 700;
    line-height: 32px;
    // text-align: center;
    color: #ffffff;
    max-height: 64px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .close-button {
    background: none;
    border: none;
    position: absolute;
    top: 42px;
    right: 32px;
    cursor: pointer;
  }

  .discount-message {
    // text-align: center;
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #ffffff;

    ::v-deep .highlight {
      color: #3b5fff;
    }
  }

  .product-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
  }

  .product-card {
    flex: 0 1 calc((100% - 48px) / 3);
    // width: 341px;
    height: auto;
    padding: 20px 32px;
    gap: 12px;
    border-radius: 100px;
    background: #3b5fff1a;
    display: flex;
    align-items: center;
    cursor: pointer;

    .product-image {
      width: 115px;
      height: 87px;
    }

    .product-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;

      .product-title {
        max-width: 150px;
        max-height: 48px;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .price-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        text-wrap: nowrap;

        .old-price {
          font-size: 12px;
          font-weight: 700;
          line-height: 28px;
          text-align: left;
          color: #ffffff;
          position: relative;

          &::before,
          &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            height: 1px;
            background-color: #ffffff;
          }

          &::before {
            top: 50%;
            transform: translateY(-50%);
          }

          &::after {
            bottom: 50%;
            transform: translateY(50%);
          }
        }

        .price.discounted {
          font-size: 20px;
          font-weight: 700;
          line-height: 28px;
          text-align: right;
          color: #e14545;
        }

        .price.blue {
          font-size: 20px;
          font-weight: 700;
          line-height: 28px;
          text-align: right;
          color: #3b5fff;
        }
      }
    }
  }

  .market-button {
    margin: 0 auto;
    // max-width: 200px;
    border-radius: 40px;
    width: 100%;
    padding: 13px;
    border-radius: 16px;
    line-height: 26px;
  }
}

@media (max-width: 768px) {
  .product-card {
    flex: 0 1 calc(50% - 16px);
  }
}

@media (max-width: 600px) {
  .product-cards {
  }
}

@media (max-width: 480px) {
  .modal.discount-modal .close-button {
    top: 12px;
    right: 12px;
  }

  .product-card {
    flex: 0 1 100%;
  }
}
</style>
