<template>
  <main class="main page-inside payment-result-page">
    <div class="section payment-section">
      <div class="page-inside-top">
        <div class="wrapper">
          <div class="title big">
            {{ $t(`payment_success`) }} {{ this.footerSupportEmail }}
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PaymentSuccessPage',
  components: {
  },
  props: [
    'footerSupportEmail',
  ],
  created() {
  },
  async mounted() {
    await this.fetchActiveDiscount(this.currencyCode);
    if (this.hasActiveDiscount) {
      this.openDiscountModal();
    }
  },
  data: function () {
    return {

    }
  },
  computed: {
    ...mapGetters('discount', ['hasActiveDiscount']),
    ...mapGetters('app', ['currencyCode']),
  },
  methods: {
    ...mapActions('discount', ['fetchActiveDiscount', 'openDiscountModal']),
  },
}
</script>